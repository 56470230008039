<template>
    <div class="Horse-List">
        <head-img desc="马场分布" :imgUrl="horseBgUrl"></head-img>
        <my-menu ref="menu" :titleList="titleList" :menuList="menuList" @changeTab="changeTab"></my-menu>
       
        <div class="horse-content">
            <div class="content">
                <div class="content-horse">
                    <div class="horse" v-for="item in horseList" :key="item.id">
                        <div class="left">
                            <el-image class="horse-img" :src="getImg(item.thumbnailUrl)" fit="cover"></el-image>
                        </div>
                        <div class="right">
                            <div class="horse-name">
                                {{item.corpName}}
                            </div>
                            <div class="text">
                                <p>{{item.businessIntroduction}}</p>
                            </div>
                            <div class="btn" @click="goDetail(item.id)">
                                <span>查看详情</span>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="page.size"
                        @current-change="currentPage"
                    >
                </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            horseBgUrl: require('@/assets/img/home/lunbo7.jpg'),
            menuList: [
                {name: '首页', path: '/'},
                {name: '马场分布'},
            ],
            titleList: [],
            horseList: [ ],
            page: {
                current: 1,
                size: 4,
            },
            model: {
                status: 1, 
                websiteFlag: 1
            },
            total: 0

        }
    },
    methods:{
        goDetail(id) {
            this.$router.push({path: '/horse/detail', query:{
                id
            }})
        },
        changeTab(data) {
            this.$refs.menu.setValue(true);
            this.model.id = this.titleList[data].id
            this.getList()
        },  
        getHorseList() {
            delete this.model.id
            this.$store.commit('loading', '.Horse-List .content-horse')
            this.$api.post('base/corp/corp-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                this.horseList = res.data.records
                res.data.records.forEach(ele=>{
                    this.titleList.push({
                        name: ele.corpName,
                        id: ele.id
                    })
                })
                this.total = res.data.total
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        },
        getList() {
            this.$store.commit('loading', '.Horse-List .content-horse')
            this.horseList = []
            this.$api.post('base/corp/corp-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                this.horseList = res.data.records
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        },
        currentPage(data) {
            this.titleList = []
            this.page.current = data
            this.getHorseList()
        }

    },
    created() {
        this.getHorseList()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .Horse-List{
        .horse-content{
            display: flex;
            justify-content: center;
            align-items: center;
            .content{
                width: 1200px;
                &-horse{
                    margin: 50px 0;
                    .horse{
                        display: flex;
                        margin-bottom: 20px;
                        border: 1px solid #ccc;
                        .left{
                            height: 300px;
                            .horse-img{
                                width: 456px;
                                height: 100%;
                                img{
                                    height: 100%;
                                }
                            }
                        }
                        .right{
                            padding: 30px 10px 10px 30px;
                            .horse-name{
                                font-size: 20px;
                                color: #333;
                                margin-bottom:  10px;
                            }
                            .text p{
                                //width: 800px;
                                padding-right: 30px;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                            }
                            .btn{
                                margin-top: 30px;
                                span{
                                    padding: 7px 30px;
                                    font-size: 16px;
                                    color: #fff;
                                    background: #c61c14
                                }
                                &:hover{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .el-pagination{
                        margin: 20px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

</style>